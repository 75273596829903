// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecentFound from "./RecentFound";
import RecentLost from "./RecentLost";
import Slider from "react-slick";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { useParams } from "react-router-dom";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
function LostAndFoundDetail({}) {
  const postList = useSelector((state) =>
    state?.allapi?.get_lost_and_Found_pub_details_list
      ? state?.allapi?.get_lost_and_Found_pub_details_list
      : {}
  );
  const post = useSelector((state) =>
    state?.allapi?.get_lost_and_Found_pub_details
      ? state?.allapi?.get_lost_and_Found_pub_details
      : {}
  );
  console.log(post?.subsubcategoryId);
  console.log(post);
  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  const ad = slider_list?.ads ? slider_list?.ads : [];
  const ads2 = [...ad].reverse();
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    dispatch(allapiAction.getsliderList({ page: "lostandfoundDetailspage" }));

    return () => {};
  }, []);
  useEffect(() => {
    dispatch(allapiAction.lostfoundsdetails({ id: id }));
    dispatch(allapiAction.lostfoundlist({ id: id }));

    return () => {};
  }, [id]);

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [contactnumberShow, setcontactnumberShow] = useState(false);
  return (
    <div>
      <div className="hometest">
        <HomeSlider />
      </div>
      <div className="container-fluid mt-30 mb-30">
        <div className="row">
          <div className="col-lg-8">
            <div className="card lost_found">
              <div className="row no-gutters">
                <div className="col-md-5 p-25">
                  <img
                    src={URL?.API_BASE_URL + post?.image}
                    alt="..."
                    className="img-fluid h-100"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body ">
                    <h4 className="card-title">{post?.name}</h4>
                    {/* <p className="card-text">
                      Number
                      <span className="mx-4">
                        {post?.phone1 !== "undefined" && post?.phone1} |{" "}
                        {post?.phone2 !== "undefined" && post?.phone2}
                      </span>
                    </p> */}
                    {/* <p className="card-text">
                      Reward
                      <span className="mx-4">
                        {post?.reword !== "undefined" && post?.reword}
                      </span>
                    </p> */}
                    {/* <hr /> */}
                    <div className="row mt-5">
                      <div className="col-4 text-secondary">
                        <span className="lostfdhe">Number</span>
                      </div>
                      <div className="col-8 ">
                        {post?.phone1 !== "undefined" && post?.phone1} -{" "}
                        {post?.phone2 !== "undefined" && post?.phone2}
                      </div>
                    </div>
                    {post?.reword !== "undefined" && post?.reword && (
                      <div className="row mt-5">
                        <div className="col-4 text-secondary">
                          <span className="lostfdhe">Reward</span>
                        </div>
                        <div className="col-8 ">
                          {post?.reword !== "undefined" && post?.reword
                            ? post?.reword
                            : "--"}{" "}
                        </div>
                      </div>
                    )}
                    <div className="row mt-5">
                      <div className="col-4 text-secondary">
                        <span className="lostfdhe">Color</span>
                      </div>
                      <div className="col-8">
                        {post?.color !== "undefined" ? post?.color : "--"}{" "}
                      </div>
                    </div>
                    {post?.subcategoryId == "lost" &&
                      post?.subsubcategoryId == "Family Member" && (
                        <div className="row mt-2 text-secondary">
                          <div className="col-4">
                            {" "}
                            <span className="lostfdhe">Relation</span>
                          </div>
                          <div className="col-8">
                            {post?.relation !== "undefined" && post?.relation}
                          </div>
                        </div>
                      )}
                    <div className="row mt-2 text-secondary">
                      <div className="col-4">
                        <span className="lostfdhe">State</span>
                      </div>
                      <div className="col-8">{post?.state}</div>
                    </div>
                    <div className="row mt-2 text-secondary">
                      <div className="col-4">
                        <span className="lostfdhe">City</span>
                      </div>
                      <div className="col-8">{post?.city}</div>
                    </div>
                    {post?.subsubcategoryId !== "Product-Document-other" && (
                      <div className="row mt-2 text-secondary">
                        <div className="col-4">
                          <span className="lostfdhe">Gender</span>
                        </div>
                        <div className="col-8">
                          {" "}
                          {post?.gender !== "undefined" && post?.gender}
                        </div>
                      </div>
                    )}
                    <div className="row mt-2 text-secondary">
                      <div className="col-4">
                        <span className="lostfdhe">Address</span>
                      </div>
                      <div className="col-8">
                        {" "}
                        {post?.address !== "undefined" && post?.address}
                      </div>
                    </div>
                    {post?.subsubcategoryId !== "Product-Document-other" && (
                      <div className="row mt-2 text-secondary">
                        <div className="col-4">
                          <span className="lostfdhe">Age</span>
                        </div>
                        <div className="col-8">
                          {" "}
                          {post?.age !== "undefined" && post?.age}
                        </div>
                      </div>
                    )}
                    {post?.subsubcategoryId !== "Product-Document-other" &&
                      post?.mark && (
                        <div className="row mt-2 text-secondary">
                          <div className="col-4">
                            <span className="lostfdhe">Body Mark</span>
                          </div>
                          <div className="col-8">
                            {" "}
                            {post?.mark !== "undefined" && post?.mark}
                          </div>
                        </div>
                      )}
                    <div className="row mt-2 ">
                      <div className="col-4">
                        <span className="lostfdhe">Published By </span> :
                      </div>
                      <div className="col-8">
                        {post?.userId?.fullName !== "undefined" &&
                          post?.userId?.fullName}
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12">
                        <a
                          className="btn btn-dark text-light btn-lg"
                          href={"tel:" + post?.phone1}
                          // onClick={()=>{setcontactnumberShow(true)}}
                        >
                          Contact Publisher{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {ad?.length > 0 && ads2?.length > 0 && (
            <div className="col-lg-4 ads_section lostdetailsads_section ">
              <div className="container">
                <div className="ps-section__right row">
                  <div className="col-lg-12 col-6 adsboxcus2">
                    {ad && (
                      <Slider {...settings}>
                        {ad &&
                          ad?.map((data, i) => {
                            return (
                              <div key={i}>
                                <a className="ps-collection" href="#">
                                  <img
                                    src={URL?.API_BASE_URL + data?.image}
                                    alt=""
                                  />
                                </a>
                              </div>
                            );
                          })}
                      </Slider>
                    )}
                  </div>
                  <div className="col-lg-12 col-6 adsboxcus2">
                    {ads2 && (
                      <Slider {...settings}>
                        {ads2 &&
                          ads2?.map((data, i) => {
                            return (
                              <div key={i}>
                                <a className="ps-collection" href="#">
                                  <img
                                    src={URL?.API_BASE_URL + data?.image}
                                    alt=""
                                  />
                                </a>
                              </div>
                            );
                          })}
                      </Slider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-15">
        <RecentFound founddata={postList?.founddata} />
        <RecentLost lostdata={postList?.lostdata} />
      </div>

      <OurWork />
    </div>
  );
}

export default LostAndFoundDetail;
